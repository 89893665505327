<template>
    <div class="appointment">
        <div class="appointment-top" v-if="false">
            <div class="appointment-top-l">
                <img src="../../assets/kaorou.jpg" alt />
            </div>
            <div class="appointment-top-r">
                <p>【食品街烤肉专门店】口味正宗，份...</p>
                <div>
                    <p>￥69.90</p>
                    <p>x1</p>
                </div>
            </div>
        </div>
        <ul class="appointment-content" v-if="isShow">
            <li>
                <p>预约店铺</p>
                <el-select v-model="shopId" placeholder="请选择使用门店">
                    <el-option v-for="item in reserveInfo.shop_lists" :key="item.shop_id" :label="item.shop_name" :value="item.shop_id">
                    </el-option>
                </el-select>
            </li>
        </ul>
        <p class="order-title">
            预约日期
            <span>（请点击下面日历选择预约日期）</span>
        </p>
        <div class="date-box flex flex-wrap" v-if="isShow">
            <div :class="['item',{'disable':item.surplus_date_nums == 0}]" v-for="(item, index) in reserveInfo.date_lists" :key="index" @click="checkDate(item)">
                <div class="top flex">
                    <p>{{ item.reserve_date }}</p>
                    <p>{{ item.reserve_week }}</p>
                </div>
                <div class="bot">
                    <p>选中预约</p>
                    <p>剩{{ item.surplus_date_nums }}个</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getReserveDates
    } from "@/http/api";
    
    export default {
        data() {
            return {
                isShow: false,
                order_id: '',
                order_cards_id: '',
                goods_id: '',
                reserveInfo: {
                    'date_lists':[], 
                    'shop_lists':[],
                },
                shopId: ''
            };
        },
        mounted() {
            let query = this.$route.query
            if (query.order_id && query.order_cards_id && query.goods_id) {
                this.order_id = query.order_id;
                this.order_cards_id = query.order_cards_id;
                this.goods_id = query.goods_id;
                this.getShopList();
            } else {
                this.$dialog.alert({
                    message: '订单预约页面参数异常, 点击跳转到订单详情看看',
                }).then(() => {
                    this.$router.go(-1)
                });
            }
        },
        methods: {
            // 获取店铺列表
            async getShopList() {
                const res = await getReserveDates({
                    data:{
                        goods_id: this.goods_id
                    }
                });
                this.reserveInfo = res.data;
                this.isShow = true;
            },
            //选择时间
            checkDate(data) {
                if (this.shopId == "") {
                    this.$toast("请先选择预约店铺");
                    return;
                } else if (data.surplus_date_nums == 0) {
                    this.$toast("很抱歉，您选择的时间段没有啦");
                    return;
                } else {
                    this.$router.push({
                        path: "/order/date",
                        query: {
                            order_id: this.order_id,
                            order_cards_id: this.order_cards_id,
                            goods_id: this.goods_id,
                            reserve_id: data.reserve_id,
                            shop_id: this.shopId
                        }
                    });
                }
            }
        },
        watch: {
            shopId(newV, oldV) {
                if (newV != "") {
                    if (this.shopId != newV) {
                        this.shopId = newV;
                    }
                }
            },
        }
    };
</script>

<style lang="scss">
    .appointment {
        width: 100%;
        height: 100%;
        background: #f6f6f6;
        padding-bottom: 0.52rem;
        min-height: 100vh;
        box-sizing: border-box;
        font-size: 0.28rem;

        .order-title {
            padding: 0 0.3rem;
            line-height: 0.88rem;
            background-color: #fff;

            span {
                font-size: 0.26rem;
                color: #7c7c7c;
            }
        }

        .date-box {
            padding: 0 0.3rem;
            background-color: #fff;

            .item {
                width: calc(100% / 3);
                box-sizing: border-box;
                text-align: center;
                padding-right: 0.3rem;
                margin-bottom: 0.4rem;

                .top {
                    background-color: #ff5400;
                    color: #fff;
                    height: 0.95rem;
                    flex-direction: column;
                    justify-content: center;
                }

                .bot {
                    border: #e0e0e0 solid 1px;
                    border-top: none;
                    height: 0.97rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    color: #ff5400;
                }

                &.disable {
                    .top {
                        background-color: #e4e3e3;
                        color: #8b8b8b;
                    }

                    .bot {
                        color: #8b8b8b;
                    }
                }
            }
        }

        .my-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            font-size: 0.38rem;
            background: #f4f4f4;
        }

        .appointment-top {
            width: 100%;
            padding: 0.35rem 0.25rem 0.39rem 0.33rem;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            background: #fff;

            .appointment-top-l {
                margin-right: 0.2rem;

                img {
                    width: 2.46rem;
                    height: 1.79rem;
                }
            }

            .appointment-top-r {
                p {
                    font-size: 0.26rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                div {
                    display: flex;
                    margin-top: 0.93rem;
                    justify-content: space-between;

                    p:nth-child(1) {
                        font-size: 0.23rem;
                        color: #e40011;
                    }

                    p:nth-child(2) {
                        font-size: 0.23rem;
                        color: #888888;
                    }
                }
            }
        }

        .appointment-top-content {
            width: 100%;
            background: #fff;
            margin-top: 0.27rem;
            padding-left: 0.23rem;
            padding-right: 0.23rem;
            box-sizing: border-box;

            li {
                border-bottom: 1px solid #eee;
                display: flex;
                height: 1rem;
                align-items: center;
                position: relative;

                p:nth-child(1) {
                    font-size: 0.27rem;
                    margin-right: 0.67rem;
                    line-height: 1rem;
                }

                #_select {
                    width: 4rem;
                    font-size: 0.24rem;
                    color: #a3a3a3;

                    img {
                        width: 0.36rem;
                        height: 0.36rem;
                    }
                }

                p:nth-child(3) {
                    img {
                        width: 0.21rem;
                        height: 0.13rem;
                    }

                    position: absolute;
                    right: 0;
                    top: -0.2rem;
                }
            }

            li:nth-child(4) {
                display: block;
                height: 3.5rem;

                .textarea {
                    width: 100%;
                    height: 1.95rem;
                    background: #f7f7f7;
                    font-size: 0.24rem;
                    padding: 0.3rem;
                    box-sizing: border-box;
                }
            }
        }

        .appointment-top-footer {
            background: #f6f6f6;
            padding-top: 1.14rem;
            padding-bottom: 0.45rem;
            box-sizing: border-box;

            p {
                border-radius: 0.44rem;
                height: 0.84rem;
                color: #fff;
                text-align: center;
                line-height: 0.84rem;
                background: #e40011;
                font-size: 0.3rem;
                margin: 0 0.34rem;
            }
        }

        .appointment-content {
            width: 100%;
            background: #fff;
            padding-left: 0.3rem;
            padding-right: 0.3rem;
            box-sizing: border-box;
            margin-top: 0.27rem;

            // padding-bottom: 0.5rem;
            li {
                height: 1.01rem;
                box-sizing: border-box;
                font-size: 0.26rem;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #f0f0f0;
                color: #000022;

                .el-input__inner {
                    border: none;
                }

                p:nth-child(1) {
                    margin-right: 0.65rem;
                }

                .el-select {
                    width: 5rem;
                    outline: none;

                    .el-input {
                        border: none;
                        outline: none;
                    }
                }

                .optionsContent {
                    border: none;
                    outline: none;
                }
            }

            li:nth-child(3) {
                p {
                    margin-right: 0.92rem;
                }
            }

            li:nth-child(4) {
                height: max-content;
                border-bottom: none;
                display: block;
                padding-top: 0.35rem;
                box-sizing: border-box;

                p {
                    margin-bottom: 0.23rem;
                }

                textarea {
                    background: #f7f7f7;
                    padding: 0.3rem;
                    box-sizing: border-box;
                    width: 100%;
                    height: 2.4rem;
                }
            }
        }
    }
</style>
